<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M5.5,4.5h13a1,1,0,0,1,1,1v12a2,2,0,0,1-2,2H6.5a2,2,0,0,1-2-2V5.5A1,1,0,0,1,5.5,4.5Z"
        />

        <path
            class="a"
            d="M17.25,6.5a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25"
        />

        <polyline
            class="a"
            points="9 10 13.5 14.5 11 16.5 11 7.5 13.5 9.5 9 14"
        />

        <path
            class="a"
            d="M.5,4.743a3,3,0,0,1,.879-2.121L2.621,1.379A3,3,0,0,1,4.743.5H19.257a3,3,0,0,1,2.122.879l1.242,1.243A3,3,0,0,1,23.5,4.743V19.258a3,3,0,0,1-.879,2.121l-1.242,1.243a3,3,0,0,1-2.122.878H4.743a3,3,0,0,1-2.122-.878L1.379,21.379A3,3,0,0,1,.5,19.258Z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'BeaconBluetooth',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
