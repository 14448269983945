<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M15.879,23.25H2.25a1.5,1.5,0,0,1-1.5-1.5V2.25A1.5,1.5,0,0,1,2.25.75h19.5a1.5,1.5,0,0,1,1.5,1.5V15.879a1.5,1.5,0,0,1-.439,1.06l-5.872,5.872A1.5,1.5,0,0,1,15.879,23.25Z"
        />

        <line class="a" x1="0.75" y1="6.75" x2="23.25" y2="6.75" />

        <line class="a" x1="6.75" y1="0.75" x2="6.75" y2="23.25" />

        <path class="a" d="M15.75,23.25v-6a1.5,1.5,0,0,1,1.5-1.5h6" />

        <line class="a" x1="11.25" y1="6.75" x2="11.25" y2="4.5" />

        <line class="a" x1="17.25" y1="6.75" x2="17.25" y2="4.5" />

        <line class="a" x1="6.75" y1="17.25" x2="4.5" y2="17.25" />

        <line class="a" x1="6.75" y1="11.25" x2="4.5" y2="11.25" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'GridRuler',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
