<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <line class="a" x1="5.25" y1="10.511" x2="10.5" y2="10.511" />

        <line class="a" x1="5.25" y1="14.261" x2="8.25" y2="14.261" />

        <line class="a" x1="5.25" y1="18.011" x2="8.25" y2="18.011" />

        <path
            class="a"
            d="M9.75,23.261H2.25a1.5,1.5,0,0,1-1.5-1.5V6.011a1.5,1.5,0,0,1,1.5-1.5H6a3.75,3.75,0,0,1,7.5,0h3.75a1.5,1.5,0,0,1,1.5,1.5v2.25"
        />

        <path
            class="a"
            d="M9.75,3.761a.375.375,0,1,1-.375.375.375.375,0,0,1,.375-.375"
        />

        <circle class="b" cx="17.25" cy="17.261" r="6" />

        <path
            class="b"
            d="M19.924,15.516,17.019,19.39a.751.751,0,0,1-1.131.08l-1.5-1.5"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'TaskListCheckIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a,
.b {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
