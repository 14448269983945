<template>
    <div v-if="capabilities && Object.keys(capabilities).length">
        <div class="l-padded u-bb">
            {{ $t('actions') }}
        </div>

        <DownlinkSwitch
            v-if="isSwitchCapabilityPair('OUTPUT_1')"
            name="OUTPUT_1"
            :output="output_1"
            @switched-downlink="triggerSwitchAction"
        />

        <DownlinkSwitch
            v-if="isSwitchCapabilityPair('OUTPUT_2')"
            name="OUTPUT_2"
            :output="output_2"
            @switched-downlink="triggerSwitchAction"
        />

        <ListItem
            v-for="(capability, capability_name, key) in capabilities_click"
            :key="key"
            class="list-item__action"
            @click="triggerAction(capability_name)"
        >
            <div class="l-inline l-gap-2">
                <ActionIcon width="24" height="24" />

                <div class="l-stack l-gap-1 l-center">
                    <h3>{{ $t(capability_name) }}</h3>
                </div>
            </div>
        </ListItem>

        <VDialog />

        <Modal name="capabilityLoadingModal" :width="300" :height="150">
            <div
                v-if="capabilityLoadingError"
                class="l-stack l-center l-center-v l-full-height"
            >
                <div class="t-warning l-padded">
                    <p>{{ $t('capabilityError') }}</p>
                </div>
            </div>

            <div v-else class="l-stack l-center l-center-v l-full-height">
                <VSpinner
                    v-if="capabilityLoading"
                    size="medium"
                    line-fg-color="#000"
                    :speed="1"
                />

                <div v-else class="t-subtle l-padded">
                    <p>{{ $t('actionDone') }}</p>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { omit } from 'lodash'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '@/utils'

import ActionIcon from './icons/ActionIcon'
import DownlinkSwitch from './DownlinkSwitch'
import ListItem from './ListItem'

export default {
    name: 'DownlinksList',
    components: {
        ActionIcon,
        DownlinkSwitch,
        ListItem,
        VSpinner,
    },
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            capabilities: null,
            capabilityLoading: true,
            capabilityLoadingError: false,
            output_1: false,
            output_2: false,
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        capabilities_click() {
            return omit(this.capabilities, [
                'ENABLE_OUTPUT_1',
                'DISABLE_OUTPUT_1',
                'ENABLE_OUTPUT_2',
                'DISABLE_OUTPUT_2',
            ])
        },
        entry() {
            return this.trackers?.find(entry => entry.id == this.id)
        },
    },
    watch: {
        entry() {
            this.readOutput()
        },
    },
    async mounted() {
        if (!this.entry) {
            return
        }
        this.readOutput()
        this.fetchCapabilities()
    },
    methods: {
        async fetchCapabilities() {
            this.capabilities = null
            if (this.entry?.asset_details.permissions.access === 'WRITE') {
                const { data: capabilities } = await httpHelper.get(
                    `lora/downlinks/?deveui=${this.entry.deveui}`
                )
                this.capabilities = capabilities
            }
        },
        readOutput() {
            this.output_1 =
                this.entry?.asset_details?.sensor_data?.d1?.value === 1
            this.output_2 =
                this.entry?.asset_details?.sensor_data?.d2?.value === 1
        },
        isCapability(capability_name) {
            return !!this.capabilities && capability_name in this.capabilities
        },
        isSwitchCapabilityPair(capability_base_name) {
            return (
                this.isCapability('ENABLE_' + capability_base_name) &&
                this.isCapability('DISABLE_' + capability_base_name)
            )
        },
        triggerSwitchAction(capability_base_name, enable) {
            if (enable) {
                this.triggerAction('ENABLE_' + capability_base_name)
            } else {
                this.triggerAction('DISABLE_' + capability_base_name)
            }
        },
        triggerAction(capability_name) {
            if (this.capabilities[capability_name]) {
                this.$modal.show('dialog', {
                    title: this.$t('actionConfirm'),
                    text: '',
                    buttons: [
                        {
                            title: this.$t('actionOK'),
                            handler: () => {
                                this.capabilityLoading = true
                                this.$modal.show('capabilityLoadingModal')
                                this.sendAction(capability_name)
                                this.$modal.hide('dialog')
                            },
                        },
                        {
                            title: this.$t('actionClose'),
                        },
                    ],
                })
            }
        },
        sendAction(capability_name) {
            if (!this.capabilities[capability_name]) {
                console.error('capability unknown.')
                return
            }

            switch (capability_name) {
                case 'ENABLE_OUTPUT_1':
                    this.output_1 = true
                    break
                case 'DISABLE_OUTPUT_1':
                    this.output_1 = false
                    break
                case 'ENABLE_OUTPUT_2':
                    this.output_2 = true
                    break
                case 'DISABLE_OUTPUT_2':
                    this.output_2 = false
                    break
            }

            httpHelper
                .post(this.capabilities[capability_name].url, {
                    deveui: this.entry.deveui,
                })
                .then(() => {
                    this.capabilityLoading = false
                    setTimeout(() => {
                        this.$modal.hide('capabilityLoadingModal')
                    }, 2000)
                })
                .catch(() => {
                    this.capabilityLoadingError = true
                    setTimeout(() => {
                        this.$modal.hide('capabilityLoadingModal')
                    }, 5000)
                })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "actionClose": "No, close",
        "actionConfirm": "Trigger this action?",
        "actionDone": "Action successful.",
        "actionOK": "Yes",
        "actions": "Actions",
        "capabilityError": "Action failed!"
    },
    "de": {
        "actionClose": "Nein, schliessen",
        "actionConfirm": "Wollen Sie diese Aktion ausführen?",
        "actionDone": "Aktion erfolgreich ausgeführt.",
        "actionOK": "Ja, ausführen",
        "actions": "Aktionen",
        "capabilityError": "Aktion fehlgeschlagen!"
    },
    "fr": {
        "actionClose": "Non, fermer",
        "actionConfirm": "Voulez-vous effectuer cette action ?",
        "actionDone": "Action réalisée avec succès.",
        "actionOK": "Oui, exécuter",
        "actions": "Actions",
        "capabilityError": "L'action à échoué !"
    },
    "it": {
        "actionClose": "No, chiudere",
        "actionConfirm": "Vuoi eseguire questa azione?",
        "actionDone": "Azione eseguita correttamente.",
        "actionOK": "Sì, eseguire",
        "actions": "Azione",
        "capabilityError": "Azione fallita!"
    }
}
</i18n>
