var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"asset-sharing-modal"},[_c('BaseHeader',{attrs:{"title":_vm.$t('title')},on:{"close":function($event){return _vm.$emit('close')}}}),_c('div',{staticClass:"l-padded l-stack l-gap-2 asset-sharing-modal__content"},[(!_vm.tokenLink)?_c('div',{staticClass:"l-stack l-gap-3 settings-form"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('description'))+" ")]),_c('div',{staticClass:"l-stack l-gap-2"},[_c('BaseInput',{attrs:{"placeholder":_vm.$t('placeholderDescription')},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('BaseMultiselect',{attrs:{"allow-empty":false,"options":_vm.timeOptions,"custom-label":function (n) { return n < 24 * 7
                                ? _vm.$tc('hour', n)
                                : _vm.$tc('week', n / 24 / 7); },"placeholder":_vm.$t('placeholderTime')},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1),_c('div',{staticClass:"l-inline l-spread l-center-v"},[_c('span',{staticClass:"clickable",on:{"click":_vm.handleClear}},[_vm._v(" "+_vm._s(_vm.$t('clear'))+" ")]),_c('BaseButton',{attrs:{"disabled":!_vm.time,"is-loading":_vm.isLoading,"size":"small"},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t('apply'))+" ")])],1)]):_vm._e(),_c('transition',{attrs:{"name":"settings-form"}},[(_vm.tokenLink)?_c('div',{staticClass:"l-stack l-gap-1 settings-form"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('confirmation'))+" ")]),_c('div',{staticClass:"l-inline l-center-v l-spread l-gap-2"},[_c('a',{staticClass:"t-truncated t-underline",attrs:{"href":_vm.tokenLink,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.tokenLink)+" ")]),(
                            _vm.isCopyActionSupported || _vm.isShareActionSupported
                        )?_c('div',{staticClass:"l-inline l-gap-1"},[(_vm.isCopyActionSupported)?_c('IconButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                                content: _vm.$t('actionCopyDone'),
                                trigger: 'manual',
                                show: _vm.isTokenLinkCopied,
                            }),expression:"{\n                                content: $t('actionCopyDone'),\n                                trigger: 'manual',\n                                show: isTokenLinkCopied,\n                            }"}],attrs:{"disabled":_vm.isTokenLinkCopied,"title":_vm.$t('actionCopy')},on:{"click":_vm.handleTokenLinkCopy}},[_c('CopyIcon')],1):_vm._e(),(_vm.isShareActionSupported)?_c('IconButton',{attrs:{"title":_vm.$t('actionShare')},on:{"click":_vm.handleTokenLinkShare}},[_c('ShareIcon')],1):_vm._e()],1):_vm._e()])]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }