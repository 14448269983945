<template>
    <div class="l-stack l-gap-2 maintenance-form">
        <BaseMultiselect
            v-model="policy"
            :placeholder="$t('maintenancePolicy')"
            :options="maintenancePolicies"
            track-by="id"
            label="name"
            block
        />

        <BaseMultiselect
            v-model="asset"
            :placeholder="$t('shared.asset')"
            :allow-empty="false"
            :options="trackers"
            track-by="id"
            :custom-label="option => option.asset_details.name"
            block
        />

        <div class="l-inline l-gap-2">
            <DateInput v-model="date" :placeholder="$t('from')" block />

            <TimeInput v-model="time" block full />
        </div>

        <BaseInput
            v-if="policy"
            v-model.number="value"
            :disabled="isSubmitting"
            :placeholder="$t('value')"
            type="number"
            block
        >
            <template #postfix>
                {{ assignment && units[assignment.policy_type] }}
            </template>
        </BaseInput>

        <RichTextInput
            v-model="notes"
            :disabled="isSubmitting"
            :placeholder="$t('notes')"
            block
        />

        <BaseCard class="maintenance-form__attachments">
            <IconButton @click="$refs.fileInput.click()">
                <AttachmentIcon />

                <input
                    ref="fileInput"
                    type="file"
                    multiple
                    @change="handleFileUpload"
                />
            </IconButton>

            <div class="l-stack">
                <div
                    v-for="(file, i) in files"
                    :key="i"
                    class="l-inline l-center-v l-gap-1"
                >
                    <FileIcon width="1em" height="1em" />

                    <span class="t-truncated">{{ file.name }}</span>

                    <IconButton
                        @click="
                            files = [
                                ...files.slice(0, i),
                                ...files.slice(i + 1),
                            ]
                        "
                    >
                        <RemoveIcon width="1em" height="1em" />
                    </IconButton>
                </div>
            </div>
        </BaseCard>

        <BaseButton
            :is-loading="isLoading"
            :disabled="isDisabled"
            type="submit"
            @click="handleSubmit"
        >
            {{ $t('shared.save') }}
        </BaseButton>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment-timezone'

import { httpHelper, measurementHelper } from '@/utils'
import AttachmentIcon from '@/components/icons/AttachmentIcon'
import BaseButton from '@/components/redesigned/BaseButton'
import BaseCard from '@/components/redesigned/BaseCard'
import BaseInput from '@/components/redesigned/BaseInput'
import BaseMultiselect from '@/components/redesigned/BaseMultiselect'
import DateInput from '@/components/redesigned/DateInput'
import FileIcon from '@/components/icons/FileIcon'
import IconButton from '@/components/IconButton'
import RemoveIcon from '@/components/icons/RemoveIcon'
import RichTextInput from '@/components/redesigned/RichTextInput'
import TimeInput from '@/components/redesigned/TimeInput'

const converters = {
    duration: value => value / 60 / 60 / 24,
    odometer: value => value / 1000,
    running_time: value => value / 60 / 60,
}

export default {
    name: 'MaintenanceForm',
    components: {
        AttachmentIcon,
        BaseButton,
        BaseCard,
        BaseInput,
        BaseMultiselect,
        DateInput,
        FileIcon,
        IconButton,
        RemoveIcon,
        RichTextInput,
        TimeInput,
    },
    props: {
        assetId: {
            type: Number,
            default: null,
        },
        policyId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            asset: null,
            assignment: null,
            date: null,
            files: [],
            isFetching: false,
            isSubmitting: false,
            notes: '',
            policy: null,
            time: '',
            units: {
                duration: this.$t('days'),
                odometer: measurementHelper.units.odometer,
                running_time: this.$t('hours'),
            },
            value: '',
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapState('maintenance', ['maintenancePolicies']),
        converter() {
            return this.assignment && converters[this.assignment.policy_type]
        },
        isDisabled() {
            return !(
                this.asset &&
                this.date &&
                this.notes.length &&
                this.time &&
                (!this.policy || typeof this.value === 'number')
            )
        },
        isLoading() {
            return this.isFetching || this.isSubmitting
        },
    },
    watch: {
        asset() {
            this.fetchAssignment()
        },
        assignment() {
            this.value = null
            if (this.asset && this.assignment) {
                const key = this.assignment.policy_type
                let value = this.asset.asset_details.sensor_data[key]?.value
                if (typeof value === 'number' && this.converter) {
                    value = Math.round(this.converter(value))
                }
                this.value = value
            }
        },
        isDisabled: {
            immediate: true,
            handler() {
                this.$emit('disabled', this.isDisabled)
            },
        },
        isLoading: {
            immediate: true,
            handler() {
                this.$emit('loading', this.isLoading)
            },
        },
        policy() {
            this.fetchAssignment()
        },
    },
    mounted() {
        this.setCurrentTime()

        if (this.assetId) {
            this.asset = this.trackers.find(item => item.asset === this.assetId)
        }

        if (this.policyId) {
            this.policy = this.maintenancePolicies.find(
                item => item.id === this.policyId
            )
        }
    },
    methods: {
        async fetchAssignment() {
            this.assignment = null
            if (this.asset && this.policy) {
                this.isFetching = true
                const { data } = await httpHelper.get(
                    'maintenance-policy-assignments/',
                    {
                        params: {
                            asset: this.asset.asset,
                            policy: this.policy.id,
                        },
                    }
                )
                if (data.results?.length) {
                    this.assignment = data.results[0]
                }
                this.isFetching = false
            }
        },
        handleFileUpload(e) {
            if (e.target.files.length) {
                this.files.push(...e.target.files)
            }
        },
        async handleSubmit() {
            this.isSubmitting = true

            const value = this.converter
                ? Math.round(this.value / this.converter(1))
                : this.value
            const timestamp = this.date.toJSON().slice(0, 11) + this.time

            const { data } = await httpHelper.post(
                'maintenance-history-records/',
                {
                    asset: this.asset.asset,
                    notes: this.notes,
                    performed_value: value,
                    policy: this.policy?.id,
                    timestamp,
                }
            )

            if (this.assignment) {
                await httpHelper.patch(
                    `maintenance-policy-assignments/${this.assignment.id}/`,
                    {
                        last_performed: timestamp,
                        last_performed_value: value,
                    }
                )
            }

            if (data.documents_url && this.files.length) {
                await Promise.all(
                    this.files.map(async file => {
                        const formData = new FormData()
                        formData.append('file', file)
                        formData.append('history_record', data.id)
                        formData.append('name', file.name)
                        await httpHelper.post(data.documents_url, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        })
                    })
                )
            }

            this.setCurrentTime()
            this.asset = null
            this.files = []
            this.notes = ''
            this.policy = null
            this.value = ''
            this.isSubmitting = false
        },
        setCurrentTime() {
            this.date = moment
                .utc()
                .startOf('day')
                .toDate()
            this.time = moment().format('HH:mm')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "days": "days",
        "hours": "hours",
        "maintenancePolicy": "Maintenance policy",
        "notes": "Notes",
        "value": "Value"
    },
    "de": {
        "days": "Tage",
        "hours": "Stunden",
        "maintenancePolicy": "Wartungsplan",
        "notes": "Notizen",
        "value": "Wert"
    },
    "fr": {
        "days": "jours",
        "hours": "heures",
        "maintenancePolicy": "Politique de maintenance",
        "notes": "Notes",
        "value": "Valeur"
    },
    "it": {
        "days": "giorni",
        "hours": "ore",
        "maintenancePolicy": "Politica di manutenzione",
        "notes": "Note",
        "value": "Valore"
    }
}
</i18n>

<style lang="scss">
.maintenance-form {
    &__attachments {
        .icon-button {
            margin: 0.5rem;

            input {
                display: none;
            }
        }

        & > div {
            &:last-child {
                padding-left: 0.5rem;
            }

            svg {
                min-width: 1em;
            }
        }
    }
}
</style>
