<template>
    <div class="asset-sharing-modal">
        <BaseHeader :title="$t('title')" @close="$emit('close')" />

        <div class="l-padded l-stack l-gap-2 asset-sharing-modal__content">
            <div v-if="!tokenLink" class="l-stack l-gap-3 settings-form">
                <p>
                    {{ $t('description') }}
                </p>

                <div class="l-stack l-gap-2">
                    <BaseInput
                        v-model="description"
                        :placeholder="$t('placeholderDescription')"
                    />

                    <BaseMultiselect
                        v-model="time"
                        :allow-empty="false"
                        :options="timeOptions"
                        :custom-label="
                            n =>
                                n < 24 * 7
                                    ? $tc('hour', n)
                                    : $tc('week', n / 24 / 7)
                        "
                        :placeholder="$t('placeholderTime')"
                    />
                </div>

                <div class="l-inline l-spread l-center-v">
                    <span class="clickable" @click="handleClear">
                        {{ $t('clear') }}
                    </span>

                    <BaseButton
                        :disabled="!time"
                        :is-loading="isLoading"
                        size="small"
                        @click="handleSubmit"
                    >
                        {{ $t('apply') }}
                    </BaseButton>
                </div>
            </div>

            <transition name="settings-form">
                <div v-if="tokenLink" class="l-stack l-gap-1 settings-form">
                    <p>
                        {{ $t('confirmation') }}
                    </p>

                    <div class="l-inline l-center-v l-spread l-gap-2">
                        <a
                            :href="tokenLink"
                            class="t-truncated t-underline"
                            target="_blank"
                        >
                            {{ tokenLink }}
                        </a>

                        <div
                            v-if="
                                isCopyActionSupported || isShareActionSupported
                            "
                            class="l-inline l-gap-1"
                        >
                            <IconButton
                                v-if="isCopyActionSupported"
                                v-tooltip="{
                                    content: $t('actionCopyDone'),
                                    trigger: 'manual',
                                    show: isTokenLinkCopied,
                                }"
                                :disabled="isTokenLinkCopied"
                                :title="$t('actionCopy')"
                                @click="handleTokenLinkCopy"
                            >
                                <CopyIcon />
                            </IconButton>

                            <IconButton
                                v-if="isShareActionSupported"
                                :title="$t('actionShare')"
                                @click="handleTokenLinkShare"
                            >
                                <ShareIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

import { httpHelper } from '@/utils'
import BaseButton from './BaseButton'
import BaseHeader from './BaseHeader'
import BaseInput from './BaseInput'
import BaseMultiselect from './BaseMultiselect'
import CopyIcon from '../icons/CopyIcon'
import IconButton from '../IconButton'
import ShareIcon from '../icons/ShareIcon'

export default {
    name: 'MeasurementsFilterModal',
    components: {
        BaseButton,
        BaseHeader,
        BaseInput,
        BaseMultiselect,
        CopyIcon,
        IconButton,
        ShareIcon,
    },
    props: {
        assetIds: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            description: '',
            isLoading: false,
            isTokenLinkCopied: false,
            time: null,
            timeOptions: [1, 8, 24, 24 * 7],
            token: null,
        }
    },
    computed: {
        isCopyActionSupported() {
            return !!window.navigator.clipboard
        },
        isShareActionSupported() {
            return !!window.navigator.share
        },
        tokenLink() {
            return this.token
                ? `${window.location.origin}/#/share/${this.token}`
                : null
        },
    },
    watch: {
        description() {
            this.token = null
        },
        time() {
            this.token = null
        },
    },
    methods: {
        handleClear() {
            this.description = ''
            this.time = null
        },
        async handleSubmit() {
            this.isLoading = true
            const { data } = await httpHelper.post('/share/tokens/', {
                description: this.description,
                valid_from: moment().toJSON(),
                valid_to: moment()
                    .add(this.time, 'hours')
                    .toJSON(),
                assets: this.assetIds,
            })
            this.token = data.token
            this.isLoading = false
        },
        handleTokenLinkCopy() {
            window.navigator.clipboard.writeText(this.tokenLink)
            this.isTokenLinkCopied = true
            setTimeout(() => {
                this.isTokenLinkCopied = false
            }, 1000)
        },
        handleTokenLinkShare() {
            window.navigator.share({
                title: process.env.VUE_APP_TITLE,
                text: this.description,
                url: this.tokenLink,
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "actionCopy": "Copy",
        "actionCopyDone": "Copied",
        "actionShare": "Share",
        "apply": "Generate link",
        "clear": "Clear",
        "confirmation": "Your sharing link is ready:",
        "description": "This function allows to share the position of assets for a defined amount of time",
        "hour": "{n} hour | {n} hours",
        "placeholderDescription": "Description",
        "placeholderTime": "Time",
        "title": "Share",
        "week": "{n} week | {n} weeks"
    },
    "de": {
        "actionCopy": "Kopieren",
        "actionCopyDone": "Kopiert",
        "actionShare": "Teilen",
        "apply": "Link generieren",
        "clear": "Löschen",
        "confirmation": "Ihr Link zum Teilen ist bereit:",
        "description": "Diese Funktion ermöglicht es, die Position von Assets für eine bestimmte Zeitspanne zu teilen",
        "hour": "{n} Stunde | {n} Stunden",
        "placeholderDescription": "Beschreibung",
        "placeholderTime": "Zeit",
        "title": "Teilen",
        "week": "{n} Woche | {n} Wochen"
    },
    "fr": {
        "actionCopy": "Copie",
        "actionCopyDone": "Copié",
        "actionShare": "Partager",
        "apply": "Générer un lien",
        "clear": "Effacer",
        "confirmation": "Votre lien de partage est prêt:",
        "description": "Cette fonction permet de partager la position des actifs pour une période de temps définie",
        "hour": "{n} heure | {n} heures",
        "placeholderDescription": "Description",
        "placeholderTime": "Temps",
        "title": "Partager",
        "week": "{n} semaine | {n} semaines"
    },
    "it": {
        "actionCopy": "Copia",
        "actionCopyDone": "Copiato",
        "actionShare": "Condividi",
        "apply": "Generare un link",
        "clear": "Chiaro",
        "confirmation": "Il vostro link di condivisione è pronto:",
        "description": "Questa funzione consente di condividere la posizione degli asset per un periodo di tempo definito",
        "hour": "{n} ora | {n} ore",
        "placeholderDescription": "Descrizione",
        "placeholderTime": "Tempo",
        "title": "Condividi",
        "week": "{n} settimana | {n} settimane"
    }
}
</i18n>

<style lang="scss">
.asset-sharing-modal {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: $color-gray-lighter-new;
    font-size: 14px;

    &__content {
        flex: 1 0 auto;
    }

    .settings-form {
        width: 100%;
        padding: 24px 16px;
        background-color: #fff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        font-size: 14px;
        color: $color-text-new;
        transition: all 0.2s;

        &-enter,
        &-leave-to {
            opacity: 0;
            transform: scale(0.95);
        }
    }
}
</style>
