var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showPicture)?[(_vm.assetPictureUrl)?[_c('div',{staticClass:"detail-image",style:({ backgroundImage: ("url(" + _vm.assetPictureUrl + ")") }),on:{"click":function($event){_vm.showImageInPopUp = true}}}),_c('PopOver',{directives:[{name:"show",rawName:"v-show",value:(_vm.showImageInPopUp),expression:"showImageInPopUp"}],staticStyle:{"z-index":"300"},on:{"exit":function($event){_vm.showImageInPopUp = false}}},[_c('img',{staticClass:"modal-asset-picture",attrs:{"src":_vm.assetPictureUrl}})])]:_vm._e(),(
                _vm.hasTachographAccess &&
                    _vm.tracker.asset_details.asset_type_type === 'truck'
            )?_c('div',{staticClass:"u-bb"},[_c('TruckInfoMeasurementFragment',{attrs:{"tracker":_vm.tracker}}),_c('TruckLinksMeasurementFragment',{attrs:{"tracker":_vm.tracker}}),_c('TruckRestHistoryMeasurementFragment',{attrs:{"tracker":_vm.tracker}}),_c('TruckTimeSummaryMeasurementFragment',{attrs:{"tracker":_vm.tracker}})],1):_vm._e()]:_vm._e(),_c('div',{staticClass:"l-inline l-gap-2 l-padded"},[_c('AssetAvatar',{attrs:{"tracker":_vm.tracker,"size":64}}),_c('div',{staticClass:"l-stack l-gap-1 l-full-width"},[_c('div',{staticClass:"l-inline l-center-v l-gap-2"},[_c('h3',[_vm._v(_vm._s(_vm.tracker.asset_details.name))]),(_vm.showActions && _vm.hasWritePermission)?_c('router-link',{attrs:{"to":("/map/assets/" + (_vm.tracker.id) + "/edit")}},[_c('IconButton',{attrs:{"title":_vm.$t('edit')}},[_c('PencilIcon')],1)],1):_vm._e(),(_vm.showActions && _vm.hasSharingAccess)?_c('IconButton',{attrs:{"title":_vm.$t('share')},on:{"click":_vm.handleShare}},[_c('ShareIcon')],1):_vm._e()],1),_c('div',{staticClass:"l-inline l-gap-1"},[(_vm.networkGsmSignal || (_vm.networkRssi && _vm.networkSnr))?_c('div',[_c('SignalStrengthIndicator',{attrs:{"network-gsm-signal":_vm.networkGsmSignal,"network-rssi":_vm.networkRssi,"network-snr":_vm.networkSnr}})],1):_vm._e(),(
                        _vm.tracker.last_gps_measurement &&
                            _vm.tracker.last_gps_measurement.kind === 'indoor'
                    )?_c('div',[_c('PinHomeIcon',{attrs:{"width":"24","height":"24","name":_vm.$t('positionIndoor')}})],1):(
                        _vm.tracker.last_gps_measurement &&
                            _vm.tracker.last_gps_measurement.kind === 'gps-fix'
                    )?_c('div',[_c('PinEarthIcon',{attrs:{"width":"24","height":"24","name":_vm.$t('positionGps')}})],1):(
                        _vm.tracker.last_gps_measurement &&
                            _vm.tracker.last_gps_measurement.kind ===
                                'wifi-report'
                    )?_c('div',[_c('PinWifiLocationIcon',{attrs:{"width":"24","height":"24","name":_vm.$t('wifiPosition')}})],1):_vm._e(),(_vm.batteryStatus)?_c('div',{staticClass:"l-inline l-center-v l-gap-1"},[_c('BatteryIcon',{attrs:{"width":"24","height":"24","battery-level-in-percent":_vm.batteryStatus.value,"warn":_vm.batteryStatus.low}}),_c('p',[_vm._v(_vm._s(_vm.batteryStatus.value)+" %")])],1):_vm._e()]),(
                    _vm.tracker.asset_details &&
                        _vm.tracker.asset_details.identification
                )?_c('div',{staticClass:"detail-line"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('identification'))+": "+_vm._s(_vm.tracker.asset_details.identification)+" ")])]):_vm._e(),(_vm.batteryStatus)?_c('div',{staticClass:"detail-line"},[_c('p',[_vm._v(_vm._s(_vm.$t('lastBatteryMeasurement'))+":")]),_c('TimeAgo',{attrs:{"from-datetime":_vm.batteryStatus.last_update}})],1):_vm._e(),(
                    _vm.tracker.last_gps_measurement &&
                        _vm.tracker.last_gps_measurement.timestamp
                )?_c('div',{staticClass:"detail-line"},[_c('p',[_vm._v(_vm._s(_vm.$t('lastGPSMeasurement'))+":")]),_c('TimeAgo',{attrs:{"from-datetime":_vm.tracker.last_gps_measurement.timestamp}}),_c('IconButton',{on:{"click":function($event){_vm.showDateInfoBox = !_vm.showDateInfoBox}}},[_c('InfoIcon',{attrs:{"width":13,"height":13}})],1)],1):_vm._e(),_c('transition',{attrs:{"name":"dropdown"}},[(_vm.showDateInfoBox)?_c('InfoBox',[_vm._v(" "+_vm._s(_vm.$t('datetimeHelp'))+" ")]):_vm._e()],1),(
                    _vm.tracker.last_gps_measurement &&
                        _vm.tracker.last_gps_measurement.gps_accuracy != null
                )?_c('div',{staticClass:"detail-line"},[_c('p',{staticClass:"sensor-data-distance"},[_vm._v(" "+_vm._s(_vm.$t('gpsAccuracy'))+": "+_vm._s(Math.round( _vm.tracker.last_gps_measurement.gps_accuracy ))+" ")])]):_vm._e(),(_vm.tracker.last_message_timestamp)?_c('div',{staticClass:"detail-line"},[_c('p',[_vm._v(_vm._s(_vm.$t('lastContact'))+":")]),_c('TimeAgo',{attrs:{"from-datetime":_vm.tracker.last_message_timestamp}})],1):_vm._e(),(_vm.hasDebugAccess)?_c('div',[_c('p',{staticClass:"t-underline t-subtle",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showAdvancedConnectionInfo = !_vm.showAdvancedConnectionInfo}}},[_vm._v(" "+_vm._s(_vm.$t('showadvancedinformation'))+" ")])]):_vm._e(),_c('transition',{attrs:{"name":"dropdown"}},[(_vm.hasDebugAccess)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdvancedConnectionInfo),expression:"showAdvancedConnectionInfo"}],staticClass:"l-stack l-gap-1"},[(_vm.tracker.model)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('model'))+": "+_vm._s(_vm.tracker.model)+" ")]):_vm._e(),(_vm.deveui)?_c('p',[_vm._v("DevEUI: "+_vm._s(_vm.deveui))]):_vm._e(),(_vm.networkSf)?_c('p',[_vm._v("SF: "+_vm._s(_vm.networkSf))]):_vm._e(),(_vm.networkRssi)?_c('p',[_vm._v("RSSI: "+_vm._s(_vm.networkRssi))]):_vm._e(),(_vm.networkSnr)?_c('p',[_vm._v("SNR: "+_vm._s(_vm.networkSnr))]):_vm._e(),(_vm.networkName)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('network'))+": "+_vm._s(_vm.networkName)+" ")]):_vm._e()]):_vm._e()]),(_vm.assetTags[_vm.tracker.id] && _vm.assetTags[_vm.tracker.id].length)?_c('div',{staticClass:"l-inline tags"},_vm._l((_vm.assetTags[_vm.tracker.id]),function(tag,index){return _c('div',{key:index,class:tag.className},[_vm._v(" "+_vm._s(_vm.$t(tag.label))+" ")])}),0):_vm._e()],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }