<template>
    <div>
        <div
            v-if="asset.asset_details.asset_type_type === 'comtac-td1'"
            class="l-padded l-inline l-gap-1"
        >
            <BaseMultiselect
                v-model="positioningMethod"
                :options="positioningMethodOptions"
            />

            <BaseButton
                ref="submitButton"
                :disabled="!positioningMethod"
                :is-loading="isLoading"
                size="small"
                @click="handlePositionGet"
            >
                {{ $t('getPosition') }}
            </BaseButton>
        </div>

        <MeasurementItem v-for="(item, key) in configData" :key="key">
            <template #icon>
                <CogIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t(key) }}:

                <h3 :class="item.class">
                    {{ item.value }}
                </h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { formatHelper, httpHelper } from '@/utils'
import BaseButton from './redesigned/BaseButton'
import BaseMultiselect from './redesigned/BaseMultiselect'
import CogIcon from './icons/CogIcon'
import MeasurementItem from './MeasurementItem'

export default {
    name: 'AssetConfigList',
    components: {
        BaseButton,
        BaseMultiselect,
        CogIcon,
        MeasurementItem,
    },
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
            positioningMethod: null,
            positioningMethodOptions: [
                'gps-only',
                'wifi-only',
                'gps-or-wifi',
                'wifi-or-gps',
                'gps-and-wifi',
            ],
        }
    },
    computed: {
        ...mapGetters('auth', ['isAdminOrStaff', 'isStaff']),
        isAlpscout() {
            return this.asset.model?.startsWith('alptracker-alpscout')
        },
        configData() {
            if (!this.asset.config) {
                return {}
            }

            return {
                ...('software_version' in this.asset.config && {
                    softwareVersion: {
                        value: this.asset.config.software_version,
                    },
                }),
                ...('serial_number' in this.asset.config && {
                    serialNumber: {
                        value: this.asset.config.serial_number,
                    },
                }),
                ...('interval' in this.asset.config && {
                    interval: {
                        value: formatHelper.hoursAndMinutesDuration(
                            this.asset.config.interval * 60
                        ),
                    },
                }),
                ...('auto_positioning_interval' in this.asset.config && {
                    autoPositioningInterval: {
                        value: formatHelper.hoursAndMinutesDuration(
                            this.asset.config.auto_positioning_interval
                        ),
                    },
                }),
                ...('default_positioning_method' in this.asset.config && {
                    defaultPositioningMethod: {
                        value: this.asset.config.default_positioning_method,
                    },
                }),
                ...('gps_fixes' in this.asset.config && {
                    gpsFixes: {
                        value: this.asset.config.gps_fixes,
                    },
                }),
                ...('long_range_trigger' in this.asset.config && {
                    longRangeTrigger: {
                        value: this.asset.config.long_range_trigger,
                    },
                }),
                ...('mid_range_trigger' in this.asset.config && {
                    midRangeTrigger: {
                        value: this.asset.config.mid_range_trigger,
                    },
                }),
                ...('min_wifi_detections' in this.asset.config && {
                    minWifiDetections: {
                        value: this.asset.config.min_wifi_detections,
                    },
                }),
                ...('ping_interval' in this.asset.config && {
                    pingInterval: {
                        value: formatHelper.hoursAndMinutesDuration(
                            this.asset.config.ping_interval * 60
                        ),
                    },
                }),
                ...('rejoin_trigger' in this.asset.config && {
                    rejoinTrigger: {
                        value: this.asset.config.rejoin_trigger,
                    },
                }),
                ...(this.isAdminOrStaff && {
                    ...('probe_address' in this.asset.config && {
                        probeAddress: {
                            value: this.asset.config.probe_address,
                        },
                    }),
                }),
                ...((this.isAlpscout || this.isStaff) && {
                    ...('gps_fix_interval' in this.asset.config && {
                        gpsFixInterval: {
                            value: formatHelper.hoursAndMinutesDuration(
                                this.asset.config.gps_fix_interval
                            ),
                        },
                    }),
                }),
                ...(this.isStaff && {
                    ...('gps_accuracy_goal' in this.asset.config && {
                        gpsAccuracyGoal: {
                            value: this.asset.config.gps_accuracy_goal,
                            class: 'sensor-data-distance-cm',
                        },
                    }),
                    ...('gps_fix_time' in this.asset.config && {
                        gpsFixTime: {
                            value: this.asset.config.gps_fix_time,
                            class: 'sensor-data-time-s',
                        },
                    }),
                    ...('firmware_version' in this.asset.config && {
                        firmwareVersion: {
                            value: this.asset.config.firmware_version,
                        },
                    }),
                }),
            }
        },
    },
    watch: {
        asset: {
            immediate: true,
            handler() {
                this.positioningMethod = this.asset.config?.default_positioning_method
            },
        },
    },
    methods: {
        async handlePositionGet() {
            try {
                this.isLoading = true
                await httpHelper.post(
                    `lora/downlinks/comtac-td1/request-position/`,
                    {
                        deveui: this.asset.deveui,
                        positioning_method: this.positioningMethod,
                    }
                )
                this.$refs.submitButton.showDoneIcon()
            } catch {
                this.$refs.submitButton.showFailIcon()
            } finally {
                this.isLoading = false
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "autoPositioningInterval": "Auto Positioning Interval",
        "defaultPositioningMethod": "Default Positioning Method",
        "firmwareVersion": "Firmware Version",
        "getPosition": "Get",
        "gpsAccuracyGoal": "GPS Accuracy Goal",
        "gpsFixInterval": "GPS Fix Interval",
        "gpsFixTime": "GPS Fix Time",
        "gpsFixes": "GPS Fixes",
        "interval": "Interval",
        "longRangeTrigger": "Long Range Trigger",
        "midRangeTrigger": "Mid Range Trigger",
        "minWifiDetections": "Min WiFi Detections",
        "pingInterval": "Ping Interval",
        "probeAddress": "Probe Address",
        "rejoinTrigger": "Rejoin Trigger",
        "serialNumber": "Serial Number",
        "softwareVersion": "Software Version"
    },
    "de": {
        "autoPositioningInterval": "Auto Positioning Interval",
        "defaultPositioningMethod": "Default Positioning Method",
        "firmwareVersion": "Firmware Version",
        "getPosition": "Get",
        "gpsAccuracyGoal": "GPS Genauigkeit",
        "gpsFixInterval": "GPS Fix Intervall",
        "gpsFixTime": "GPS Fix Zeit",
        "gpsFixes": "GPS Fixes",
        "interval": "Messintervall",
        "longRangeTrigger": "Long Range Trigger",
        "midRangeTrigger": "Mid Range Trigger",
        "minWifiDetections": "Min WiFi Detections",
        "pingInterval": "Ping Interval",
        "probeAddress": "Sondenadresse",
        "rejoinTrigger": "Rejoin Trigger",
        "serialNumber": "Serial Number",
        "softwareVersion": "Software Version"
    },
    "fr": {
        "autoPositioningInterval": "Intervalle de positionnement automatique",
        "defaultPositioningMethod": "Méthode de positionnement par défaut",
        "firmwareVersion": "Version du firmware",
        "getPosition": "Get",
        "gpsAccuracyGoal": "Précision du GPS",
        "gpsFixInterval": "GPS Intervalle fixe",
        "gpsFixTime": "GPS Temps fixe",
        "gpsFixes": "GPS Fixes",
        "interval": "Intervalle",
        "longRangeTrigger": "Trigger longue portée",
        "midRangeTrigger": "Trigger moyenne portée",
        "minWifiDetections": "Min Wifi Detections",
        "pingInterval": "Intervalle de ping",
        "probeAddress": "Adresse de la sonde",
        "rejoinTrigger": "Rejoin Trigger",
        "serialNumber": "Numéro de série",
        "softwareVersion": "version du software"
    },
    "it": {
        "autoPositioningInterval": "Auto Positioning Interval",
        "defaultPositioningMethod": "Default Positioning Method",
        "firmwareVersion": "Versione del firmware",
        "getPosition": "Get",
        "gpsAccuracyGoal": "Precisione GPS",
        "gpsFixInterval": "Intervallo di GPS",
        "gpsFixTime": "Tempo di GPS",
        "gpsFixes": "GPS Fixes",
        "interval": "Intervallo",
        "longRangeTrigger": "Long Range Trigger",
        "midRangeTrigger": "Mid Range Trigger",
        "minWifiDetections": "Min WiFi Detections",
        "pingInterval": "Ping Interval",
        "probeAddress": "Indirizzo della sonda",
        "rejoinTrigger": "Rejoin Trigger",
        "serialNumber": "Serial Number",
        "softwareVersion": "Software Version"
    }
}
</i18n>
