var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"asset-maintenance-policies"},[_c('ListHeader',{staticClass:"u-bb"},[_c('template',{slot:"title"},[_vm._v(_vm._s(_vm.$t('title')))]),_c('template',{slot:"accessory"},[_c('div',{staticClass:"l-inline l-gap-2"},[_c('router-link',{attrs:{"to":{
                        name: 'dashboardMaintenanceHistory',
                        query: {
                            asset: _vm.asset,
                        },
                    }}},[_c('IconButton',{attrs:{"no-button":""}},[_c('MenuIcon',{attrs:{"width":"18","height":"18"}})],1)],1),_c('IconButton',{attrs:{"title":_vm.$t('addHint')},on:{"click":function($event){return _vm.handleAdd()}}},[_c('AddIcon',{attrs:{"width":"18","height":"18"}})],1)],1)])],2),_c('List',{attrs:{"is-loading":_vm.isLoading}},_vm._l((_vm.assignments),function(assignment,i){return _c('ListItem',{key:i},[_c('router-link',{staticClass:"l-full-width",attrs:{"to":{
                    name: 'dashboardMaintenanceHistory',
                    query: {
                        asset: assignment.asset,
                        policy: assignment.policy,
                    },
                }}},[_c('div',{staticClass:"l-inline l-gap-2 l-center-v"},[_c('TaskListCheckIcon',{class:assignment.due_in ? 'active' : 'inactive',attrs:{"width":"24","height":"24"}}),_c('div',{staticClass:"l-grow-1 l-stack l-gap-1"},[_c('p',[_vm._v(_vm._s(assignment.policy_name))]),_c('p',{staticClass:"t-small t-subtle"},[_vm._v(" "+_vm._s(_vm.assignmentStates[i])+" ")])]),_c('IconButton',{attrs:{"title":_vm.$t('addHint')},on:{"click":function($event){$event.preventDefault();return _vm.handleAdd(assignment.policy)}}},[_c('AddIcon',{attrs:{"width":"18","height":"18"}})],1)],1)])],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }