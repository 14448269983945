<template>
    <div>
        <ListHeader class="u-bb">
            <div slot="title">{{ $t('alerts') }}</div>
            <IconButton slot="accessory" no-button @click="goToAssetRules">
                <PencilIcon width="18" height="18" />
            </IconButton>
        </ListHeader>
        <List>
            <ListItem
                v-for="(notification, index) in notifications"
                :key="index"
                :clickable="false"
            >
                <div class="l-inline l-full-width l-gap-2">
                    <div>
                        <AlarmBellIcon
                            width="24"
                            height="24"
                            class="t-warning"
                            color="currentcolor"
                        />
                    </div>

                    <div class="l-stack l-gap-1 l-grow-1">
                        <p>
                            {{ notification.title }}
                        </p>
                        <TimeAgo
                            :from-datetime="notification.timestamp"
                            class="t-small t-subtle"
                        />
                    </div>
                </div>
            </ListItem>
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <VSpinner size="medium" line-fg-color="#000" :speed="1" />
            </div>
            <div
                v-else-if="nextUrl === null && notifications.length === 0"
                class="l-inline l-center-v l-center l-padded l-gap-1 t-good"
            >
                <CheckIcon width="16" height="16" color="currentcolor" />
                <p class="t-small l-center-v">{{ $t('nonotifications') }}</p>
            </div>
            <div v-else-if="nextUrl !== null" class="l-stack l-center l-padded">
                <p class="t-subtle t-small">
                    <a href="#" @click.prevent="loadMore">{{
                        $t('loadmore')
                    }}</a>
                </p>
            </div>
        </List>
    </div>
</template>

<script>
import { httpHelper } from '@/utils'
import AlarmBellIcon from '@/components/icons/AlarmBellIcon'
import CheckIcon from '@/components/icons/CheckIcon'
import IconButton from '@/components/IconButton'
import List from '@/components/List'
import ListHeader from '@/components/ListHeader'
import ListItem from '@/components/ListItem'
import PencilIcon from '@/components/icons/PencilIcon'
import TimeAgo from '@/components/TimeAgo'
import VSpinner from 'vue-simple-spinner'

export default {
    name: 'AssetNotifications',
    components: {
        AlarmBellIcon,
        CheckIcon,
        IconButton,
        List,
        ListHeader,
        ListItem,
        PencilIcon,
        TimeAgo,
        VSpinner,
    },
    props: {
        asset: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            notifications: [],
            isLoading: false,
            nextUrl: null,
            loadLimit: 3,
        }
    },
    computed: {
        initialUrl() {
            return `alertrule-notifications/?asset=${this.asset}&limit=${this.loadLimit}&offset=0&resolved=false`
        },
    },
    watch: {
        asset() {
            this.notifications = []
            this.nextUrl = null
            this.loadMore()
        },
    },
    mounted() {
        this.loadMore()
    },
    methods: {
        async loadMore() {
            this.isLoading = true
            this.notifications.push(...(await this.fetchMoreNotifications()))
            this.isLoading = false
        },
        async fetchMoreNotifications() {
            let url = this.nextUrl
            if (this.nextUrl === null) {
                url = this.initialUrl
            }
            const { data } = await httpHelper.get(url)
            this.nextUrl = data.next
            return data.results
        },
        goToAssetRules() {
            this.$router.push({
                name: 'ruleList',
                query: { assets: this.asset + '' },
            })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "alerts": "Alerts",
        "loadmore": "Load more",
        "nonotifications": "No alerts."
    },
    "de": {
        "alerts": "Alarme",
        "loadmore": "Weitere anzeigen",
        "nonotifications": "Keine Alarme."
    },
    "fr": {
        "alerts": "Alarmes",
        "loadmore": "Chargez plus",
        "nonotifications": "Aucune alarmes."
    },
    "it": {
        "notifications": "Allarmi",
        "loadmore": "Mostra di più",
        "nonotifications": "Nessun allarme."
    }
}
</i18n>
