<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        :fill-color="color"
        @click="$emit('click')"
    >
        <path
            d="M12 13.2772V21.9689"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5px"
        ></path>
        <path
            d="M19.8042 2.0311C24.3969 6.6235 24.3969 14.0696 19.8042 18.662"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5px"
        ></path>
        <path
            d="M16.9083 4.9218C19.9036 7.9169 19.9036 12.7731 16.9083 15.7681"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5px"
        ></path>
        <path
            d="M4.1958 2.0311C-0.3969 6.6235 -0.3969 14.0696 4.1958 18.662"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5px"
        ></path>
        <path
            d="M7.0917 4.9218C4.0964 7.9169 4.0964 12.7731 7.0917 15.7681"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5px"
        ></path>
        <path
            d="M8.9323 10.2095C8.9323 12.571 11.4887 14.0469 13.5338 12.8662C14.483 12.3182 15.0677 11.3055 15.0677 10.2095C15.0677 7.848 12.5113 6.3721 10.4662 7.5528C9.517 8.1008 8.9323 9.1135 8.9323 10.2095"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5px"
        ></path>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'GatewayIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a,
.b {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}

.b {
    fill-rule: evenodd;
}
</style>
