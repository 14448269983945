<template>
    <div class="maintenance-form-modal">
        <BaseHeader :title="$t('title')" @close="$emit('close')" />

        <div class="l-padded maintenance-form-modal__content">
            <MaintenanceForm
                ref="form"
                :asset-id="assetId"
                :policy-id="policyId"
                @disabled="isDisabled = $event"
                @loading="isLoading = $event"
            />
        </div>

        <footer
            class="l-padded l-inline l-justify-end maintenance-form-modal__actions"
        >
            <BaseButton
                size="small"
                :is-loading="isLoading"
                :disabled="isDisabled"
                @click="handleSubmit"
            >
                {{ $t('shared.save') }}
            </BaseButton>
        </footer>
    </div>
</template>

<script>
import BaseButton from './BaseButton'
import BaseHeader from './BaseHeader'
import MaintenanceForm from '../MaintenanceForm'

export default {
    name: 'MaintenanceFormModal',
    components: {
        BaseButton,
        BaseHeader,
        MaintenanceForm,
    },
    props: {
        assetId: {
            type: Number,
            default: null,
        },
        policyId: {
            type: Number,
            default: null,
        },
        update: {
            type: Function,
            default: null,
        },
    },
    data() {
        return {
            isDisabled: false,
            isLoading: false,
        }
    },
    methods: {
        async handleSubmit() {
            this.isLoading = true
            await this.$refs.form.handleSubmit()
            this.update?.()
            this.$emit('close')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "title": "Add new maintenance record"
    },
    "de": {
        "title": "Neuen Wartungssatz hinzufügen"
    },
    "fr": {
        "title": "Ajouter un nouvel de maintenance"
    },
    "it": {
        "title": "Aggiungere un nuovo record di manutenzione"
    }
}
</i18n>

<style lang="scss">
.maintenance-form-modal {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $color-gray-lighter-new;
    font-size: 14px;
    overflow-y: auto;

    &__content {
        flex: 1 0 auto;
        margin-bottom: 68px;
    }

    &__actions {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 1px solid $color-gray-light-new;
        background-color: #fff;
    }

    .maintenance-form {
        button[type='submit'] {
            display: none;
        }
    }
}
</style>
